.lvd-query-builder {
  width: 1100px;
}
.lvd-query-builder .rules-group-container {
  border: 0;
  background: #fff;
}
.lvd-query-builder .rules-group-container .rules-group-container {
  border: 1px dashed #aab5bf;
  background: #f1f2f4;
}
.lvd-query-builder .rules-list > ::after,
.lvd-query-builder .rules-list > ::before {
  width: 8px;
}
.lvd-query-builder .rule-container,
.lvd-query-builder .rules-group-container {
  border-radius: 2px;
}

.lvd-query-builder .rule-container {
  min-height: 38px;
  border-style: dashed;
  border-color: #aab5bf;
}

.query-builder,
.query-builder * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.query-builder {
  font-family: sans-serif;
}

.query-builder .hide {
  display: none;
}

.query-builder .pull-right {
  float: right !important;
}

.query-builder .btn {
  text-transform: none;
  z-index: 2;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.03),
    0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.03);
}

.query-builder .btn.focus,
.query-builder .btn:focus,
.query-builder .btn:hover {
  color: #333;
  text-decoration: none;
}

.query-builder .btn.active,
.query-builder .btn:active {
  background-image: none;
  outline: 0px none;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.125) inset;
}

.query-builder .btn-success {
  color: #fff;
  background-color: #81c534;
  border-color: #81c534;
}

.query-builder .btn-primary {
  color: #fff;
  background-color: #99a5b2;
  border-color: #99a5b2;
}

.query-builder .btn-danger {
  color: #fff;
  background-color: #ff4d4d;
  border-color: #ff4d4d;
}

.rule-actions .btn-danger {
  color: #ff4d4d;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}

.rule-actions .btn-danger.active,
.rule-actions .btn-danger.focus,
.rule-actions .btn-danger:active,
.rule-actions .btn-danger:focus,
.rule-actions .btn-danger:hover {
  color: #fff;
  background-color: #ef2022;
  border-color: #ef2022;
}

.query-builder .btn-success.active,
.query-builder .btn-success.focus,
.query-builder .btn-success:active,
.query-builder .btn-success:focus,
.query-builder .btn-success:hover {
  color: #fff;
  background-color: #79c021;
  border-color: #79c021;
}

.query-builder .btn-primary.active,
.query-builder .btn-primary.focus,
.query-builder .btn-primary:active,
.query-builder .btn-primary:focus,
.query-builder .btn-primary:hover {
  color: #fff;
  background-color: #3f536c;
  border-color: #3f536c;
}

.query-builder .btn-danger.active,
.query-builder .btn-danger.focus,
.query-builder .btn-danger:active,
.query-builder .btn-danger:focus,
.query-builder .btn-danger:hover {
  color: #fff;
  background-color: #ef2022;
  border-color: #ef2022;
}

.query-builder .btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.query-builder .btn-group > .btn {
  position: relative;
  float: left;
}

.query-builder .btn-group > .btn:first-child {
  margin-left: 0px;
}

.query-builder .btn-group > .btn:first-child:not(:last-child) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.query-builder .btn-group > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.query-builder .btn-group .btn + .btn,
.query-builder .btn-group .btn + .btn-group,
.query-builder .btn-group .btn-group + .btn,
.query-builder .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.query-builder .btn-xs,
.query-builder .btn-group-xs > .btn {
  padding: 3px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

/*mobile portrait*/
@media (min-width: 320px) and (max-width: 767px) {
  .lvd-query-builder {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .lvd-query-builder {
    width: 100%;
  }
}
